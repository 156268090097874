<template>
  <div>
    <!-- <b-row class="align-items-center">
      <b-col md="4">
        <b-form-group
          label="Campus"
          invalid-feedback="Campus is required."
          ref="camp"
        >
          <v-select
            v-model="currentCamp"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="campuses"
            label="branch"
            :reduce="(opt) => opt.id"
            :clearable="false"
            placeholder="Select campus"
          />
        </b-form-group>
      </b-col>
      <b-button
        class="ml-1"
        :disabled="dataLoading"
        variant="primary"
        @click="LoadData()"
      >
        Show
      </b-button>
    </b-row> -->
    <b-row v-if="dataLoading" align-h="center">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <template v-else>
      <b-card no-body class="adjust-border" v-if="false">
        <b-card-header class="p-1">
          <h4 class="mb-0">Collection Overview</h4>
        </b-card-header>

        <b-row class="text-center mx-0">
          <b-col
            md="3"
            class="border-top border-right d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0">Total</b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ collection.total }}
            </h3>
          </b-col>
          <b-col
            md="3"
            class="border-top border-right d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0"> Expenses </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ collection.expense }}
            </h3>
          </b-col>

          <b-col
            md="3"
            class="border-top border-right d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0"> Cash in Hand </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ collection.cashHand }}
            </h3>
          </b-col>
          <b-col
            md="3"
            class="border-top d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0"> Cash in Bank </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{ collection.cashBank }}
            </h3>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="6">
          <b-row class="hover-card">
            <b-col md="6" sm="6">
              <b-card bg-variant="success" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Collection</h4>
                <p
                  class="font-weight-bolder mb-0 font-large-1 line-height-1 d-flex align-items-center justify-content-between"
                >
                  <feather-icon icon="TrendingUpIcon" size="26" />
                  {{ collection.total }}
                </p>
              </b-card>
            </b-col>

            <b-col md="6" sm="6">
              <b-card bg-variant="danger" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Expense</h4>
                <p
                  class="font-weight-bolder mb-0 text-right font-large-1 line-height-1 d-flex align-items-center justify-content-between"
                >
                  <feather-icon icon="TrendingDownIcon" size="26" />
                  {{ collection.expense }}
                </p>
              </b-card>
            </b-col>

            <b-col md="6" sm="6">
              <b-card bg-variant="info" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Cash in Hand</h4>
                <p
                  class="font-weight-bolder mb-0 text-right font-large-1 line-height-1 d-flex align-items-center justify-content-between"
                >
                  <feather-icon icon="LayersIcon" size="26" />
                  {{ collection.cashHand }}
                </p>
              </b-card>
            </b-col>

            <b-col md="6" sm="6">
              <b-card bg-variant="warning" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Cash in Bank</h4>
                <p
                  class="font-weight-bolder mb-0 text-right font-large-1 line-height-1 d-flex align-items-center justify-content-between"
                >
                  <feather-icon icon="DatabaseIcon" size="26" />
                  {{ collection.cashBank }}
                </p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <b-row class="hover-card">
            <!-- lg="3"
            md="6"
            sm="6" -->
            <b-col md="6" sm="6" v-for="(item, ind) in summaryObj" :key="ind">
              <b-card no-body>
                <b-card-body
                  class="d-flex justify-content-between align-items-center"
                >
                  <div class="truncate">
                    <h2 class="mb-25 font-weight-bolder">
                      {{ item.value }}
                    </h2>
                    <span class="text-capitalize font-weight-bold">
                      {{ item.text }}
                    </span>
                  </div>
                  <b-avatar
                    :variant="`light-${color[ind % color.length]}`"
                    size="45"
                  >
                    <feather-icon
                      size="21"
                      :icon="`${summaryIcons[ind % summaryIcons.length]}`"
                    />
                  </b-avatar>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="6">
          <b-card body-class="p-1">
            <b-card-title class="mb-25">Student Attendance Ratio</b-card-title>

            <vue-apex-charts
              type="donut"
              height="350"
              :options="stdAttDonut.chartOptions"
              :series="stdAttDonut.series"
            />
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card body-class="p-1">
            <b-card-title class="mb-25">Staff Attendance Ratio</b-card-title>

            <vue-apex-charts
              type="donut"
              height="350"
              :options="staffAttDonut.chartOptions"
              :series="staffAttDonut.series"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">
                Attendance
                <b-badge variant="light-success" class="ml-50">
                  {{
                    new Date().toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              :items="items"
              :fields="fields"
              responsive
              hover
              :busy="itemLoading"
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>
              <template #head(details)="data">
                <div>
                  <span>(P, A, L, Late)</span>
                </div>
              </template>
              <template #head(st_details)="data">
                <div>
                  <span>(P, A, L, Late)</span>
                </div>
              </template>

              <template #cell(campus)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(totalStudents)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(details)="data">
                <b-badge variant="light-success" class="mr-25">
                  {{ data.item.present }}
                </b-badge>
                <b-badge variant="light-danger" class="mr-25">
                  {{ data.item.absent }}
                </b-badge>
                <b-badge variant="light-info" class="mr-25">
                  {{ data.item.leave }}
                </b-badge>
                <b-badge variant="light-warning">
                  {{ data.item.late }}
                </b-badge>
              </template>
              <template #cell(st_total)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(st_details)="data">
                <b-badge variant="light-success" class="mr-25">
                  {{ data.item.st_present }}
                </b-badge>
                <b-badge variant="light-danger" class="mr-25">
                  {{ data.item.st_absent }}
                </b-badge>
                <b-badge variant="light-info" class="mr-25">
                  {{ data.item.st_leave }}
                </b-badge>
                <b-badge variant="light-warning">
                  {{ data.item.st_late }}
                </b-badge>
              </template>
              <template #cell(present)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(absent)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(leave)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(late)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>

        <b-col lg="6" v-if="false">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">
                Staff Attendance
                <b-badge variant="light-success" class="ml-50">
                  {{
                    new Date().toLocaleDateString("en-UK", {
                      year: "numeric",
                      day: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              :items="staffAtt"
              :fields="fields"
              responsive
              hover
              :busy="itemLoading"
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(campus)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(totalStudents)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(present)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(absent)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(leave)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(late)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-card no-body>
            <b-card-header>
              <div>
                <b-card-sub-title class="mb-25"
                  >Fee Outstanding</b-card-sub-title
                >
                <b-card-title class="font-weight-bolder">
                  Rs. {{ totalBalance }}
                </b-card-title>
              </div>
            </b-card-header>

            <b-card-body v-if="!chartLoading">
              <vue-apex-charts
                type="bar"
                height="600"
                :options="feeChart.chartOptions"
                :series="feeChart.series"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="12">
          <b-card no-body>
            <b-card-header class="p-1">
              <h4 class="mb-0">
                Fee Status
                <b-badge variant="light-success" class="ml-50">
                  {{
                    new Date().toLocaleDateString("en-UK", {
                      year: "numeric",
                      month: "short",
                    })
                  }}
                </b-badge>
              </h4>
            </b-card-header>
            <b-table
              class=""
              show-empty
              :items="feeData"
              :fields="feeFields"
              responsive
              hover
              :busy="itemLoading"
            >
              <template #table-busy>
                <div
                  class="d-flex justify-content-center mb-3"
                  style="margin-top: 50px"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    type="grow"
                    variant="primary"
                    label="Spinning"
                  ></b-spinner>
                </div>
              </template>

              <template #cell(campus)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(total)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(received)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(discount)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
              <template #cell(balance)="data">
                <b-badge variant="light-primary">
                  {{ data.value }}
                </b-badge>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row class="mt-1">
        <b-col xl="3" lg="4">
          <b-card>
            <vue-apex-charts
            type="radialBar"
            height="270"
            :options="chartOptions"
            :series="[67]"
            />
          </b-card>
        </b-col>
      </b-row> -->
    </template>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BSpinner,
  BBadge,
  BFormInput,
  BFormGroup,
  BForm,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BSpinner,
    BBadge,
    BFormInput,
    BFormGroup,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    vSelect,
    flatPickr,
    VueApexCharts,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    const chartColors = {
      column: {
        series1: "#826af9",
        series2: "#d2b0ff",
        bg: "#f8d3ff",
      },
      success: {
        shade_100: "#7eefc7",
        shade_200: "#06774f",
      },
      donut: {
        series1: "#ffe700",
        series2: "#00d4bd",
        series3: "#826bf8",
        series4: "#2b9bf4",
        series5: "#FFA1A1",
      },
      area: {
        series3: "#a4f8cd",
        series2: "#60f2ca",
        series1: "#2bdac7",
      },
    };
    return {
      color: ["primary", "warning", "success", "danger", "info", "secondary"],
      summaryIcons: [
        // "UserIcon",
        // "UsersIcon",
        "HelpCircleIcon",
        "FileTextIcon",
        "CheckIcon",
        "LogOutIcon",
      ],
      dataLoading: false,
      campuses: [],
      currentCamp: this.$store.state.userData.cId,
      itemLoading: false,
      staffAtt: [],
      items: [],
      fields: [
        { label: "campus", key: "campus" },
        { label: "students", key: "totalStudents" },
        { label: "details", key: "details" },
        { label: "staff", key: "st_total" },
        { label: "st_details", key: "st_details" },

        // { label: "total", key: "totalStudents" },
        // { label: "present", key: "present" },
        // { label: "absent", key: "absent" },
        // { label: "leave", key: "leave" },
        // { label: "late", key: "late" },
      ],
      feeData: [],
      feeFields: [
        { label: "campus", key: "campus" },
        { label: "total", key: "total" },
        { label: "received", key: "received" },
        { label: "discount", key: "discount" },
        { label: "balance", key: "balance" },
      ],
      collection: {
        total: 114500,
        expense: 13000,
        cashHand: 25000,
        cashBank: 76500,
      },
      summaryObj: [
        // {text: "staff", value: 210},
        // {text: "students", value: 3202},
        { text: "inquiries", value: 55 },
        { text: "registrations", value: 130 },
        { text: "new admissions", value: 89 },
        { text: "left students", value: 32 },
      ],
      chartLoading: true,
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 0,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "65%",
            },
            track: {
              // background: "#fff",
              strokeWidth: "100%",
            },
            dataLabels: {
              name: {
                offsetY: -5,
                color: "#5e5873",
                fontSize: "1rem",
              },
              value: {
                offsetY: 15,
                color: "#5e5873",
                fontSize: "1.714rem",
                formatter: function (val) {
                  return val.toFixed(2) + "%";
                },
              },
            },
          },
        },
        colors: [$themeColors.success],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          // dashArray: 8,
          lineCap: "round",
        },
        labels: ["Revenue"],
      },
      stdAttDonut: {
        series: [1459, 238, 55, 70],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
            formatter: function (seriesName, opts) {
              return [
                seriesName,
                `(${opts.w.globals.series[opts.seriesIndex]})`,
              ];
            },
          },
          colors: [
            $themeColors.success,
            $themeColors.danger,
            $themeColors.info,
            $themeColors.warning,
            // chartColors.donut.series2,
            // chartColors.donut.series5,
            // chartColors.donut.series1,
            // chartColors.donut.series3,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Present",
                    formatter(val) {
                      return val.globals.series[0];
                    },
                  },
                },
              },
            },
          },
          labels: ["Present", "Absent", "Leave", "Late"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                // chart: {
                //   height: 380,
                // },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                // chart: {
                //   height: 320,
                // },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      staffAttDonut: {
        series: [450, 38, 23, 57],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
            formatter: function (seriesName, opts) {
              return [
                seriesName,
                `(${opts.w.globals.series[opts.seriesIndex]})`,
              ];
            },
          },
          colors: [
            $themeColors.success,
            $themeColors.danger,
            $themeColors.info,
            $themeColors.warning,
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Present",
                    formatter(val) {
                      // console.log(val.globals.series);
                      return val.globals.series[0];
                    },
                  },
                },
              },
            },
          },
          labels: ["Present", "Absent", "Leave", "Late"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                // chart: {
                //   height: 380,
                // },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                // chart: {
                //   height: 320,
                // },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      feeChart: {
        series: [
          {
            name: "Total",
            data: [],
          },
          {
            name: "Received",
            data: [],
          },
          {
            name: "Discount",
            data: [],
          },
          {
            name: "Balance",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            toolbar: {
              show: false,
            },
          },
          colors: [
            $themeColors.primary,
            $themeColors.success,
            $themeColors.info,
            $themeColors.warning,
          ],
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: "100%",
              // endingShape: "rounded",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
          },
          legend: {
            position: "top",
            // horizontalAlign: 'left',
          },
          tooltip: {
            y: {
              formatter(val) {
                return `Rs. ${parseInt(val)}`;
              },
            },
          },
        },
      },
    };
  },
  created() {
    this.LoadCampuses();
  },
  computed: {
    totalBalance() {
      return this.feeData.reduce((sum, el) => sum + parseInt(el.balance), 0);
    },
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadData() {
      console.log(this.currentCamp);
    },

    async LoadCampuses() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
      // Math.floor(Math.random() * (max - min + 1) + min);
      this.items = this.campuses.map((el) => {
        let total = Math.floor(100 + Math.random() * 900);
        let absent = Math.floor(Math.random() * 50) + 1;
        let leave = Math.floor(Math.random() * 5) + 1;
        let late = Math.floor(Math.random() * 20) + 1;

        let st_total = Math.floor(100 + Math.random() * 900);
        let st_absent = Math.floor(Math.random() * 50) + 1;
        let st_leave = Math.floor(Math.random() * 5) + 1;
        let st_late = Math.floor(Math.random() * 20) + 1;
        return {
          campus: el.branch,
          totalStudents: total,
          present: total - absent - late - leave,
          absent: absent,
          leave: leave,
          late: late,
          st_total: st_total,
          st_present: st_total - st_absent - st_late - st_leave,
          st_absent: st_absent,
          st_leave: st_leave,
          st_late: st_late,
        };
      });
      // console.log(this.items);
      // this.staffAtt = this.campuses.map((el) => {
      //   let total = Math.floor(100 + Math.random() * 900);
      //   let absent = Math.floor(Math.random() * 50) + 1;
      //   let leave = Math.floor(Math.random() * 5) + 1;
      //   let late = Math.floor(Math.random() * 20) + 1;
      //   return {
      //     campus: el.branch,
      //     totalStudents: total,
      //     present: total - absent - late - leave,
      //     absent: absent,
      //     leave: leave,
      //     late: late,
      //   };
      // });
      this.feeData = this.campuses.map((el) => {
        let total = Math.floor(100000 + Math.random() * 900000);
        let received = Math.floor(
          Math.random() * (total - 100000 + 1) + 100000
        );
        let discount = Math.floor(Math.random() * (20000 - 10000 + 1) + 10000);
        return {
          campus: el.branch,
          total: total,
          received: received,
          discount: discount,
          balance: Math.abs(total - received - discount),
        };
      });

      this.feeData.forEach((el, ind) => {
        this.feeChart.chartOptions.xaxis.categories.push(el.campus);
        this.feeChart.series[0].data.push(el.total);
        this.feeChart.series[1].data.push(el.received);
        this.feeChart.series[2].data.push(el.discount);
        this.feeChart.series[3].data.push(el.balance);
      });
      // console.log(this.feeChart);
      this.chartLoading = false;
      this.itemLoading = false;
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .adjust-border .border-right {
    border-right: none !important;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
